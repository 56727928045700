/*
 * @Author: fangjun
 * @Date: 2023-01-30 08:45:20
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-07-04 17:00:39
 * @FilePath: \src\permission.js
 */
import router from '@/router'
import NProgress from '@/utils/progress'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
import { usePermissionStore } from '@/store/modules/permission'
import { useUserStore } from '@/store/modules/user'
import { useWsStore } from '@/store/modules/websocket'
import { cloudControlWebSocketUrl } from '@/api/common'
import { ElMessage } from 'element-plus'
import { getRobot } from '@/api/home'
import { sendTrackInfo } from '@/api/common'

const whiteList = ['/login', '/auth-redirect', '/forgetPassword'] // no redirect whitelist

const maxRetries = 5; // ws最大重试次数
let retries = 0; // 当前重试次数
const initWebsocket = (wsStore, token) => {
  wsStore.create(`${cloudControlWebSocketUrl}?token=${token}&platform=${import.meta.env.VITE_APP_TOKEN_BEAR}`)
  window.wsStore = wsStore
  wsStore.websocket.onopen = function () {
    console.log('websocket连接了')
  }
  wsStore.websocket.onclose = function (e) {
    console.log('websocket close')
    console.log(e)
    // 断线重连
    if(retries < maxRetries) {
      const wsStore = useWsStore()
      let hasToken = getToken()
      if (!wsStore.websocket) {
        var delay = Math.pow(2, retries) * 1000;
        console.log('当前正在重连中...')
        ElMessage({
          message: '正在尝试重新连接',
          type: 'warning'
        })
        setTimeout(() => {
          initWebsocket(wsStore, hasToken)
        }, delay);
        retries++;
      }
      
    } else {
      console.log('ws超出最大重试次数，无法重连')
    }
  }
  wsStore.websocket.onerror = function (e) {
    console.log('websocket出错了')
    console.log(e)
  }
}

router.beforeEach((to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  let hasToken = getToken()
  const permissionStore = usePermissionStore()

  if (hasToken) {
    
    
    const userStore = useUserStore()

    if (userStore.roleId === null) {
      userStore
        .getUserInfoAndCustomAreaInfo()
        .then(() => {
          // init websocket
          const wsStore = useWsStore()
          if (!wsStore.websocket) {
            initWebsocket(wsStore, hasToken)
          }
          permissionStore.generateRoutes().then(() => {
            if (permissionStore.routes.length === 0) {
              ElMessage({
                message: '当前账号未分配权限，请联系朴津售后人员',
                type: 'warning'
              })
              userStore.logout().then(() => {
                if (from.path === '/login') {
                  NProgress.done()
                } else {
                }
                router.push({ name: '登录' })
              })
            } else {
              permissionStore.routes.forEach((route) => {
                router.addRoute(route) // 动态添加可访问路由表
              })
              if (to.path === '/login') {
                next({ path: '/' })
              } else {
                next({ ...to, replace: true })
              }
            }
          })
        })
        .catch(() => {
          userStore.logout().then(() => {
            if (from.path === '/login') {
              NProgress.done()
            } else {
            }
            router.push({ name: '登录' })
          })
        })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}&params=${JSON.stringify(to.query ? to.query : to.params)}`)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
  sendTrackInfo()
})
